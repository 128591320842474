import { Link } from "gatsby"
import { useStaticQuery } from "gatsby"
import { graphql } from 'gatsby'
import PropTypes from "prop-types"
import React from "react"
import Img from "gatsby-image"
import "./header.scss"

const Header = ({ siteTitle }) => {
  const data = useStaticQuery(graphql`
    query {
      banner__landscape: file(relativePath: { eq: "banner.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1800) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      banner__portrait: file(relativePath: { eq: "banner-portrait.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <header
      className={'header'}
      style={{
        background: `black`,
        margin: `0 auto`,
        position: 'relative',
        height: '400px',
        width: '100%',
        overflow: 'hidden',
      }}
    >
      <div
        className='header__banner-image-container-landscape'
      >
        <Img fluid={data.banner__landscape.childImageSharp.fluid} />
      </div>
      <div
        className='header__banner-image-container-portrait'
      >
        <Img fluid={data.banner__portrait.childImageSharp.fluid} />
      </div>
      <div
        style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          zIndex: 3,
        }}
      >
        <h1
          style={{
            margin: '30px',
            fontFamily: '"Cambay", sans-serif',
            fontStyle: 'normal',
            fontWeight: 'bold',
          }}
        >
          <Link
            to="/"
            style={{
              color: `white`,
              textDecoration: `none`,
            }}
          >
            {siteTitle}
          </Link>
        </h1>
        <nav
          style={{
            position: 'absolute',
            bottom: 0,
            right: 0,
            zIndex: 3,
            fontStyle: 'normal',
            fontWeight: 'bold',
          }}
        >
          <Link
            to="/"
            style={{
              textDecoration: `none`,
            }}
          >
            WHO AM I?
          </Link>
          <Link
            to="/blog"
            style={{
              textDecoration: `none`,
            }}
          >
            BLOG
          </Link>
          <Link
            to="/projects"
            style={{
              textDecoration: `none`,
            }}
          >
            PROJECTS
          </Link>
        </nav>
      </div>
    </header>
  );
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header;
